import Model from '@/dc-it/models/Model'
import Select from '@/dc-it/types/Select'
import Field from '@/dc-it/types/Field'
import MultipleRelation from '@/dc-it/types/MutipleRelation'
import AccountTypeField from '@/dc-it/models/AccountTypeField'
import TransactionType from '@/dc-it/models/TransactionType'

export default class AccountType extends Model {
    endpoint = 'account_types';

    related = ['account_type_fields', 'transaction_types']

    types = {
      1: 'Ahorro',
      2: 'Crédito',
    }

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('name', 'Nombre').disabled(),
      new Select('type', 'Tipo', this.types, '1').disabled(),
      new MultipleRelation('account_type_fields', 'Campos adicionales', AccountTypeField).hide(),
      new MultipleRelation('transaction_types', 'Tipos de transacción', TransactionType).hide(),
    ]

    clone = () => AccountType;
}
